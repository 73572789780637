<template>
  <div id="services">
    <v-card>
        <v-data-table
            :headers="headers"
            :items="services"
            :search="search"
            :single-expand="singleExpand"
            :expanded.sync="expanded"
            :loading="isLoading"
            item-key="_id"
            show-expand
        >
            <template v-slot:top>
                <v-toolbar
                    flat
                >
                    <v-toolbar-title>Services</v-toolbar-title>
                    <v-divider
                        class="mx-4"
                        inset
                        vertical
                    ></v-divider>
                    <v-spacer></v-spacer>
                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details
                    ></v-text-field>
                    <v-spacer></v-spacer>
                    <v-dialog
                        v-model="dialog"
                        max-width="500px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                color="primary"
                                dark
                                class="mb-2"
                                v-bind="attrs"
                                v-on="on"
                            >
                                New Service
                            </v-btn>
                        </template>
                        <v-card>
                        <v-card-title>
                            <span class="headline">{{ formTitle }}</span>
                        </v-card-title>
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field
                                            v-model="editedItem.title"
                                            label="Title"
                                            hint="Choose a suitable name for this service"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field
                                            v-model="editedItem.plattform"
                                            label="Plattform"
                                            hint="f.e. 'Instagram'"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-select
                                            v-model="editedItem.provider"
                                            :items="providers"
                                            item-text="title"
                                            item-value="_id"
                                            label="Provider"
                                        >

                                        </v-select>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field
                                            v-model.number="editedItem.increasePercentage"
                                            label="Bonus"
                                            suffix="%"
                                            type="number"
                                            hint="The customer will get an extra % of his ordered quantity"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field
                                            v-model.number="editedItem.serviceId"
                                            label="Service"
                                            type="number"
                                            hint="Choose the exact same Id for this service from the SMM-Panel"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-combobox
                                        v-model="editedItem.variations"
                                        label="Product Variations"
                                        hint="Include all WooCommerce Variations-Id's you want't to use this service for. If there is no Variation use the Product-Id"
                                        multiple
                                        chips
                                        ></v-combobox>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                @click="close"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                class="primary"
                                text
                                @click="save"
                            >
                                Save
                            </v-btn>
                        </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogDelete" max-width="600px">
                    <v-card>
                        <v-card-title class="headline">Are you sure you want to delete this service?</v-card-title>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn text @click="closeDelete">Cancel</v-btn>
                                <v-btn color="error" text @click="deleteItemConfirm">Delete</v-btn>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-icon
                    small
                    color="primary"
                    class="mr-2"
                    @click="editItem(item)"
                >
                    mdi-pencil
                </v-icon>
                <v-icon
                    small
                    color="error"
                    @click="deleteItem(item)"
                >
                    mdi-delete
                </v-icon>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">
                                        Services
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <v-chip
                                                v-for="variation in item.variations"
                                                :key="variation"
                                                class="mr-1"
                                            >{{variation}}</v-chip>
                                        </td>
                                    </tr>
                                </tbody>                            
                        </template>
                    </v-simple-table>
                </td>
            </template>
        </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { ApiFactory } from '../../api/apiFactory'
import Date from '../../helpers/date'

const Services = ApiFactory.get('services')
const Providers = ApiFactory.get('providers')


export default {
  data() {
    return {
      dialog: false,  
      dialogDelete: false,
      isLoading: false,
      services: [],
      providers: [],
      search: '',
      expanded: [],
      singleExpand: false,
      headers: [
          {
            text: 'Title',
            align: 'start',
            value: 'title',
          },
          { text: 'Plattform', value: 'plattform' },
          { text: 'Provider', value: 'provider.title' },
          { text: 'Bonus %', value: 'increasePercentage'},
          { text: 'Created', value: 'createdAt' },
          { text: 'Updated', value: 'updatedAt' },
          { text: 'Actions', value: 'actions', sortable: false },
          { text: '', value: 'data-table-expand' }
        ],
        editedIndex: -1,
        editedItem: {
            _id: '',
            title: '',
            plattform: '',
            increasePercentage: '',
            provider:  '',
            serviceId: '',
            variations: ''
        },
        defaultItem: {
            id: '',
            title: '',
            plattform: '',
            increasePercentage: '',
            provider: '',
            serviceId: '',
            variations: ''
        },
    }
  },
  computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'New Service' : 'Edit Service'
      },
  },
  watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
  },
  created() {
    this.fetch()
  },
  methods: {
    async fetch() {
      this.isLoading = true
      const services = await Services.get()
      this.services = Date.formatMultipleDates(services.data)
      const providers = await Providers.get()
      this.providers = providers.data
      this.isLoading = false
    },
    async editItem (item) {
        this.editedIndex = this.services.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
    },
    deleteItem (item) {
        this.editedIndex = this.services.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
    },
    async deleteItemConfirm () {
        await Services.deleteService(this.editedItem._id)
        this.closeDelete()
        this.fetch()
    },
    close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
    },
    closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
    },
    async save () {
        if (this.editedIndex > -1) {
            await Services.updateService(this.editedItem._id, this.editedItem)
        } else {
            delete this.editedItem._id
            await Services.createService(this.editedItem)
        }
        this.close()
        this.fetch()
      },
  },
  
}
</script>


<style>

</style>