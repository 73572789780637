import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/messaging'

let firebaseConfig = {
    apiKey: "AIzaSyC9QzOYI2pHlB49nD1Rg0IZ1Uhl8vj_f2s",
    authDomain: "followerpro-719a2.firebaseapp.com",
    databaseURL: "https://followerpro-719a2.firebaseio.com",
    projectId: "followerpro-719a2",
    storageBucket: "followerpro-719a2.appspot.com",
    messagingSenderId: "803434221127",
    appId: "1:803434221127:web:a57c5e958e79413352eb62"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);

  // utils
  const db = firebase.firestore()
  const auth = firebase.auth()
  const messaging = firebase.messaging()


  // collection references
  const usersCollection = db.collection('users')

  export {
      auth,
      usersCollection,
      messaging
  }