<template>
   <v-app id="inspire">
    <v-app-bar
      app
      clipped-left
      flat
      height="72"
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <Logo /> 
      <v-spacer></v-spacer>
      <v-btn @click="getMessagingToken"  class="ml-2" text v-if="!subscribed">
        <v-icon>mdi-bell</v-icon>
      </v-btn>
      <v-btn @click="toogleMode" class="ml-2" text>
        <v-icon  v-if="$vuetify.theme.dark">mdi-white-balance-sunny</v-icon>
        <v-icon v-if="!$vuetify.theme.dark">mdi-brightness-2</v-icon>
      </v-btn>
      <v-btn @click="logout()"  class="ml-2" text>
        <v-icon>mdi-exit-to-app</v-icon>
      </v-btn>
    </v-app-bar>

    
    <v-navigation-drawer
      v-model="drawer"
      v-for="item in menu"
      :key="item.link"
      app
      clipped
      left
    >
      <v-list 
       v-model="menu"
       >
        <v-list-item
          link
          v-for="(item, i) in menu"
          :key="i"
          :to=item.link
        >
          <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title >{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <slot />
    </v-main>

  
  </v-app>
</template>

<script>
  import Logo from '../components/Logo'
  import axios from 'axios';

  import { messaging } from '../firebase'

  export default {
    components: {
      Logo
    },
    props: {
      source: String,
    },
    data: () => (
      { 
        drawer: null,
        subscribed: false,
        menu: [
          {
            title: 'Dashboard',
            link: '/',
            icon: 'mdi-home'
          }, 
          {
            title: 'Orders',
            link: 'orders',
            icon: 'mdi-basket'
          },
          {
            title: 'Services',
            link: 'services',
            icon: 'mdi-toolbox'
          },
          {
            title: 'Providers',
            link: 'providers',
            icon: 'mdi-store'
          }  
        ]
      }
    ),
    mounted() {
      this.listenTokenRefresh();
    },
    methods: {
      toogleMode() {
        this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      },
      logout() {
        this.$store.dispatch('logout')
      },
      getMessagingToken () {
        messaging.getToken().then(async (token) => {
            if (token) {
                    const currentMessageToken = window.localStorage.getItem('messagingToken')
                    console.log('token will be updated', currentMessageToken != token)
                    if(currentMessageToken != token){
                        await this.saveToken(token)
                    }
                } else {
                    console.log('No Instance ID token available. Request permission to generate one.');
                    this.notificationsPermisionRequest();
                }
        }).catch(function(err) {
            console.log('An error occurred while retrieving token. ', err);
        });
    },
    notificationsPermisionRequest () {
        messaging.requestPermission()
        .then(() => {
            this.getMessagingToken();
        })
        .catch((err) => {
            console.log('Unable to get permission to notify.', err);
        }); 
    },
    listenTokenRefresh() {
        const currentMessageToken = window.localStorage.getItem('messagingToken')
        console.log('currentMessageToken', currentMessageToken);
        if(!currentMessageToken){
            this.subscribed = false
            messaging.onTokenRefresh(function() {
                messaging.getToken().then(function(token) {
                    this.saveToken(token);
                    this.subscribed = true
                }).catch(function(err) {
                    console.log('Unable to retrieve refreshed token ', err);
                });
            });
        } else {
          this.subscribed = true
        }

        console.log(this.subscribed)
    },
    saveToken(token) {
        console.log('tokens', token)
        axios.post(`https://us-central1-followerpro-719a2.cloudfunctions.net/GeneralSubscription`,{ token })
        .then(response => {
            window.localStorage.setItem('messagingToken',token)
            console.log(response)
        }).catch((err) => {
            console.log(err)
        })
    },
    }
  }
</script>