import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { auth } from './firebase'
import vuetify from './plugins/vuetify';
import VueClipboard from 'vue-clipboard2'
import SidebarLeft from './layouts/SidebarLeft';
import Centered from './layouts/Centered';
import './registerServiceWorker'


Vue.component('sidebar-left-layout', SidebarLeft);
Vue.component('centered-layout', Centered);

Vue.use(VueClipboard)

Vue.config.productionTip = false;

let app
auth.onAuthStateChanged(user => {
  if (!app) {
    app = new Vue({
      router,
      store,
      vuetify,
      render: h => h(App)
    }).$mount('#app')
  }

  if (user) {
    store.dispatch('fetchUserProfile', user)
  }
})
