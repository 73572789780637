import Orders from './orders'
import Providers from './providers'
import Services from './services'
import ServiceOrders from './serviceOrders'
import Kpis from './kpis'


const endpoints = {
    orders: Orders,
    providers: Providers,
    services: Services,
    serviceOrders: ServiceOrders,
    kpis: Kpis
}

export const ApiFactory = {
    get: name => endpoints[name]
}