import Api from './index'

const resource = '/service-orders'

export default {
    get() {
        return Api.get(`${resource}`)
    },
    createServiceOrder(payload) {
        return Api.post(`${resource}`, payload)
    },
    updateServiceOrder(serviceOrderId, payload) {
        return Api.patch(`${resource}/${serviceOrderId}`, payload)
    },
    deleteServiceOrder(serviceOrderId) {
        return Api.delete(`${resource}/${serviceOrderId}`)

    },
    reorderServiceOrder(payload) {
        return Api.post(`${resource}/reorder`, payload)
    }
}