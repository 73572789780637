<template>
  <v-card class="mx-auto" elevation="15">
      <v-card-title class="text-uppercase">Charge</v-card-title>
      <v-list-item two-line>
      <v-list-item-content>
        <div class="overline mb-4">
          TOTAL
        </div>
        <v-list-item-title class="headline mb-1" v-if="charge">
          <span class="red--text">{{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'USD' }).format(charge.total)}}</span>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>

<script>
import { ApiFactory } from '../api/apiFactory'
const Kpis = ApiFactory.get('kpis')

export default {
    name: 'ChargeCard',
    props: [
        'charge'
    ]
}
</script>