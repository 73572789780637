<template>
    <div class="logo">
        <v-img
        v-if="!$vuetify.theme.dark"
        class="logo"
        :src="require('@/assets/followerPro.png')"
        />
        <v-img
        v-if="$vuetify.theme.dark"
        class="logo"
        :src="require('@/assets/followerPro-white.png')"
        />
    </div>
        
</template>

<script>
export default {

}
</script>

<style>
.logo {
    max-width: 200px;
}

</style>

