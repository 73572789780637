<template>
  <div id="orders">
    <v-card>
      <v-data-table
        :headers="headers"
        :items="orders"
        :page.sync="page"
        :items-per-page.sync="limit"
        :server-items-length="itemCount"
        :search="search"
        :loading="isLoading"
        :single-expand="singleExpand"
        :expanded.sync="expanded"
        item-key="_id"
        show-expand
        class="data-table"
      >
        <template v-slot:top>
          <v-toolbar
            flat
          >
            <v-toolbar-title>Orders</v-toolbar-title>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              @click:append="fetch"
              v-on:keyup.enter="fetch"
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              @click="fetch"
            >
              Update
              <v-icon right>mdi-refresh</v-icon>
            </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:item.status="{ item }">
          <v-chip
            :color="getStatusColor(item.status)"
            dark
          >
            {{ item.status }}
          </v-chip>
        </template>
        <template v-slot:item.serviceStatus="{ item }">
          <v-chip
            color="error"
            dark
            v-if="checkServiceOrderStatus(item) === 'error'"
          >
            <v-icon>mdi-alert-circle</v-icon>
          </v-chip>
          <v-chip
            color="success"
            dark
            v-else-if="checkServiceOrderStatus(item) === 'success'"
          >
            <v-icon>mdi-check</v-icon>
          </v-chip>
          <v-chip
            color="warning"
            dark
            v-else
          >
            <v-icon>mdi-alert</v-icon>
          </v-chip>
        </template>
        <template v-slot:item.lineItems="{ item }">
          <v-chip-group>
            <v-chip
            v-for="lineItem in item.lineItems"
            :key="lineItem._id"
            label
            x-small
            ref="textToCopy"
            v-clipboard:copy="lineItem.link"
            v-clipboard:success="onCopy"
            >
              {{lineItem.link}}
            </v-chip>
          </v-chip-group>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="py-4">
            <h3>Items</h3>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Title
                    </th>
                    <th class="text-left">
                      Link
                    </th>
                    <th class="text-left">
                      Quantity
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="lineItem in item.lineItems"
                    :key="lineItem._id"
                  >
                    <td>{{ lineItem.title }}</td>
                    <td>{{ lineItem.link }}</td>
                    <td>{{ lineItem.quantity }}</td>
                    <td>
                      <v-btn class="accent" @click="reorderService(item, lineItem)" :loading="isLoading" :key="lineItem._id">Reorder</v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <h3>Service Orders</h3>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Id
                    </th>
                    <th class="text-left">
                      Link
                    </th>
                    <th class="text-left">
                      Quantity
                    </th>
                    <th class="text-left">
                      Start Count
                    </th>
                    <th class="text-left">
                      Remain Count
                    </th>
                    <th class="text-left">
                      Status
                    </th>
                    <th class="text-left">
                      Charge
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="serviceOrder in item.serviceOrders"
                    :key="serviceOrder._id"
                  >
                    <td>{{ serviceOrder.panelId }}</td>
                    <td>{{ serviceOrder.link }}</td>
                    <td>{{ serviceOrder.quantity }}</td>
                    <td>{{ serviceOrder.startCount }}</td>
                    <td>{{ serviceOrder.remainCount }}</td>
                    <td>{{ serviceOrder.status }}</td>
                    <td>{{ serviceOrder.charge }} {{ serviceOrder.currency }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-row class="ma-2" justify="end">
              <v-dialog
                        v-model="serviceOrderDialog"
                        max-width="500px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                color="success"
                                dark
                                class="mb-2"
                                v-bind="attrs"
                                v-on="on"
                            >
                                New Service Order
                            </v-btn>
                        </template>
                        <v-card>
                        <v-card-title>
                            <span class="headline">New Service Order</span>
                        </v-card-title>
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12">
                                        <v-select
                                            v-model="serviceOrder.id"
                                            :items="services"
                                            item-text="title"
                                            item-value="_id"
                                            label="Service"
                                        >
                                        </v-select>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field
                                            v-model="serviceOrder.link"
                                            label="Link"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field
                                            v-model="serviceOrder.quantity"
                                            label="Quantity"
                                            type="number"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                @click="close"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                class="primary"
                                text
                                @click="save(item)"
                            >
                                Save
                            </v-btn>
                        </v-card-actions>
                        </v-card>
                    </v-dialog>
            </v-row>  
          </td>
        </template>
      </v-data-table>
    </v-card>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbarTimeout"
      :color="snackbarColor"
      class="text-uppercase"
    >
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="copySnackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { ApiFactory } from '../../api/apiFactory'
import Date from '../../helpers/date'
import LineItems from '../../helpers/lineItems'
const Orders = ApiFactory.get('orders')
const Services = ApiFactory.get('services')
const ServiceOrders = ApiFactory.get('serviceOrders')

export default {
  data() {
    return {
      page: 1,
      limit: 10,
      pageCount: 0,
      itemCount:0,
      isLoading: false,
      orders: [],
      search: '',
      snackbar: false,
      snackbarText: '',
      snackbarColor: 'error',
      snackbarTimeout: 2000,
      expanded: [],
      singleExpand: false,
      headers: [
          {
            text: 'Order ID',
            align: 'start',
            value: 'orderId',
            width: '7%'
          },
          { text: 'Order Status', value: 'status', width: '10%' },
          { text: 'Service Status', value: 'serviceStatus', width: '10%'},
          { text: 'Items', value: 'lineItems', width: "43%"},
          { text: 'Created', value: 'createdAt', width: '12.5%' },
          { text: 'Updated', value: 'updatedAt', width: '12.5%' },
          { text: 'Links', value: 'links', align: ' d-none' },
          { text: '', value: 'data-table-expand', width: '5%' }
        ],
        serviceOrderDialog: false,
        services: [],
        defaultServiceOrder: {
          id: '',
          link: '',
          quantity: 0
        },
        serviceOrder: {
          id: '',
          link: '',
          quantity: 0
        }
    }
  },
  created() {
    this.fetch()
  },
  methods: {
    async fetch() {
      this.isLoading = true
      Orders.get(this.page, this.limit, this.search).then(res => {
        let data = res.data.orders
        this.pageCount = Number(res.data.totalPages)
        this.itemCount = Number(res.data.totalItems)
        this.page = Number(res.data.currentPage)

        data = Date.formatMultipleDates(data)
        data = LineItems.lineItemsLinksToString(data)

        this.orders = data
        this.isLoading = false

      })
      const services = await Services.get()
      this.services = services.data
    },
    close () {
        this.serviceOrderDialog = false
        this.$nextTick(() => {
          this.serviceOrder = Object.assign({}, this.defaultServiceOrder)
        })
    },
    getStatusColor(status) {
      if (status === 'completed') return 'green'
      else if (status === 'pending') return 'orange'
      else if (status === 'canceled') return 'red'
      else if (status === 'failed') return 'red'
      else if (status === 'refunded') return 'grey'
      else return 'grey'
    },
    async reorderService(order, lineItem) {
      try {
        const service = await Services.findServiceByVariantId(lineItem.variationId)
        if(service.data._id) {
          this.isLoading = true
          const { data } = await ServiceOrders.reorderServiceOrder({
            link: lineItem.link,
            order: order._id,
            service: service.data._id,
            quantity: lineItem.quantity,
            runs: lineItem.runs || undefined,
            interval: lineItem.interval || undefined
          })
          const editedIndex = this.orders.indexOf(order)
          this.orders[editedIndex].serviceOrders.push(data)
          this.isLoading = false
        } else {
          throw Error
        }
        
      } catch(err) {
        this.snackbar = true
        this.snackbarText = 'Error - Is there a Service for this product?'
        this.snackbarColor = 'error'
      } 
    },
    async orderService(order, serviceOrder) {
      try {
          this.isLoading = true
          const { data } = await ServiceOrders.reorderServiceOrder({
            link: serviceOrder.link,
            order: order._id,
            service: serviceOrder.id,
            quantity: serviceOrder.quantity,
            runs: serviceOrder.runs || undefined,
            interval: serviceOrder.interval || undefined
          })
          const editedIndex = this.orders.indexOf(order)
          this.orders[editedIndex].serviceOrders.push(data)
          this.isLoading = false
        
      } catch(err) {
        this.snackbar = true
        this.snackbarText = 'Error - Is there a Service for this product?'
        this.snackbarColor = 'error'
      } 
    },
    async save(order) {
      await this.orderService(order, this.serviceOrder)
      this.$nextTick(() => {
          this.serviceOrder = Object.assign({}, this.defaultServiceOrder)
      })
    },
    onCopy() {
      this.snackbar = true
      this.snackbarText = 'Link copied!'
      this.snackbarColor = 'success'
    },
    checkServiceOrderStatus(item) {
      const serviceOrdersCount = item.serviceOrders.filter((obj) => obj.panelId).length
      const lineItemsCount = item.lineItems.length

      if (serviceOrdersCount >= lineItemsCount) {
        return "success"
      } else if(serviceOrdersCount === 0) {
        return "error"
      } else {
        return "warning"
      }
    }
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
    page: {
      handler () {
        this.fetch()
      },
      deep: true,
    },
    limit: {
      handler () {
        this.fetch()
      },
      deep: true,
    },
  }
  
}
</script>


<style>
.data-table table{
  table-layout : fixed;
}

</style>