<template>
  <div id="dashboard" class="container">
    <v-row>
      <v-spacer></v-spacer>
      <v-col
        cols="12"
        sm="6"
        md="4"
      >
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="dates"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateRangeText"
              label="Date range"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dates"
            no-title
            scrollable
            :max="maxDate"
            range
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="menu = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="fetch(), $refs.menu.save(dates)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6" lg="3" v-for="provider in providers" :key="provider._id">
        <balance-card :provider="provider" />
      </v-col>
      <v-col cols="12" md="6" lg="3">
        <charge-card :charge="charge" />
      </v-col>
    </v-row>
    
  </div>
</template>

<script>
import BalanceCard from '../components/BalanceCard.vue'
import ChargeCard from '../components/ChargeCard.vue'
import { ApiFactory } from '../api/apiFactory'

const Providers = ApiFactory.get('providers')
const Kpis = ApiFactory.get('kpis')

export default {
  components: { 
    BalanceCard,
    ChargeCard 
  },
  data() {
    return {
      providers: [],
      dates: [new Date().toISOString().substr(0, 10)],
      maxDate: new Date().toISOString().substr(0, 10),
      menu: false,
      charge: {
        total: 0
      }
    }
  },
  created() {
    this.fetch()
  },
  methods: {
    fetch() {
      this.getProviders()
        this.getCharge()
      },
      async getCharge() {
        const res = await Kpis.getCharge(this.dateRange.from, this.dateRange.to)
        const data = res.data[0]
        this.charge = data
      },
    async getProviders() {
      const { data } = await Providers.get()
      this.providers = data
    }
  },
  computed: {
      dateRangeText () {
        if (this.dates.length > 1) {
          let date = new Date(this.dateRange.to)
          let toDate = date.setDate(date.getDate() - 1)
          return this.dateRange.from + ' - ' + new Date(toDate).toISOString().substr(0, 10)
        } else {
          return this.dateRange.from
        }
      },
      dateRange () {
        let range = {}
        if (this.dates.length > 1) {
          if ( new Date(this.dates[0]) > new Date(this.dates[1])) {
            let date = new Date(this.dates[0])
            let toDate = date.setDate(date.getDate() + 1)
            range.from = this.dates[1]
            range.to = new Date(toDate).toISOString().substr(0, 10)
          } else {
            let date = new Date(this.dates[1])
            let toDate = date.setDate(date.getDate() + 1)
            range.from = this.dates[0]
            range.to = new Date(toDate).toISOString().substr(0, 10)
          }
        }  else {
          let fromDate = new Date(this.dates[0])
          let toDate = fromDate.setDate(fromDate.getDate() + 1)
          range.from = this.dates[0]
          range.to = new Date(toDate).toISOString().substr(0, 10)
        }
        return range 
      }
  },

}
</script>

<style>

</style>