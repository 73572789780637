<template>
  <v-card class="mx-auto" elevation="15">
      <v-card-title class="text-uppercase">{{provider.title}}</v-card-title>
      <v-list-item two-line>
      <v-list-item-content v-if="provider.balance.balance">
        <div class="overline mb-4">
          BALANCE
        </div>
        <v-list-item-title class="headline mb-1">
          <span class="green--text">{{new Intl.NumberFormat('de-DE', { style: 'currency', currency: provider.balance.currency }).format(provider.balance.balance)}}</span>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>

<script>
export default {
    name: 'BalanceCard',
    props: [
        'provider'
    ]
}
</script>