<template>
  <div id="providers">
    <v-card>
        <v-data-table
            :headers="headers"
            :items="providers"
            :search="search"
            :loading="isLoading"
            item-key="_id"
        >
            <template v-slot:top>
                <v-toolbar
                    flat
                >
                    <v-toolbar-title>Providers</v-toolbar-title>
                    <v-divider
                        class="mx-4"
                        inset
                        vertical
                    ></v-divider>
                    <v-spacer></v-spacer>
                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details
                    ></v-text-field>
                    <v-spacer></v-spacer>
                    <v-dialog
                        v-model="dialog"
                        max-width="500px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                color="primary"
                                dark
                                class="mb-2"
                                v-bind="attrs"
                                v-on="on"
                            >
                                New Provider
                            </v-btn>
                        </template>
                        <v-card>
                        <v-card-title>
                            <span class="headline">{{ formTitle }}</span>
                        </v-card-title>
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field
                                            v-model="editedItem.title"
                                            label="Title"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field
                                            v-model="editedItem.apiUrl"
                                            label="API URL"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field
                                            v-model="editedItem.apiKey"
                                            label="API key"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-textarea
                                            auto-grow
                                            rows="1"
                                            row-height="15"
                                            v-model="editedItem.note"
                                            label="Note"
                                        ></v-textarea>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                @click="close"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                class="primary"
                                text
                                @click="save(attrs)"
                            >
                                Save
                            </v-btn>
                        </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogDelete" max-width="600px">
                    <v-card>
                        <v-card-title class="headline">Are you sure you want to delete this provider?</v-card-title>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn text @click="closeDelete">Cancel</v-btn>
                                <v-btn color="error" text @click="deleteItemConfirm">Delete</v-btn>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-icon
                    small
                    color="primary"
                    class="mr-2"
                    @click="editItem(item)"
                >
                    mdi-pencil
                </v-icon>
                <v-icon
                    small
                    color="error"
                    @click="deleteItem(item)"
                >
                    mdi-delete
                </v-icon>
            </template>
        </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { ApiFactory } from '../../api/apiFactory'
import Date from '../../helpers/date'

const Providers = ApiFactory.get('providers')

export default {
  data() {
    return {
      dialog: false,  
      dialogDelete: false,
      isLoading: false,
      providers: [],
      search: '',
      headers: [
          {
            text: 'Title',
            align: 'start',
            value: 'title',
          },
          { text: 'API URL', value: 'apiUrl' },
          { text: 'API Key', value: 'apiKey' },
          { text: 'Note', value: 'note' },
          { text: 'Created', value: 'createdAt' },
          { text: 'Updated', value: 'updatedAt' },
          { text: 'Actions', value: 'actions', sortable: false }
        ],
        editedIndex: -1,
        editedItem: {
            _id: '',
            title: '',
            apiUrl: '',
            apiKey: '',
            note: ''
        },
        defaultItem: {
            _id: '',
            title: '',
            apiUrl: '',
            apiKey: '',
            note: ''
        },
    }
  },
  computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'New Provider' : 'Edit Provider'
      },
  },
  watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
  },
  created() {
    this.fetch()
  },
  methods: {
    async fetch() {
      this.isLoading = true
      const { data } = await Providers.get()
      this.isLoading = false
      this.providers = Date.formatMultipleDates(data)
    },
    editItem (item) {
        this.editedIndex = this.providers.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
    },
    deleteItem (item) {
        this.editedIndex = this.providers.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
    },
    async deleteItemConfirm () {
        await Providers.deleteProvider(this.editedItem._id)
        this.closeDelete()
        this.fetch()
    },
    close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
    },
    closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
    },
    async save () {
        if (this.editedIndex > -1) {
            await Providers.updateProvider(this.editedItem._id, this.editedItem)
        } else {
            delete this.editedItem._id
            await Providers.createProvider(this.editedItem)
        }
        this.close()
        this.fetch()
      }
  },
  
}
</script>


<style>

</style>