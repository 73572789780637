import Api from './index'

const resource = '/providers'

export default {
    get() {
        return Api.get(`${resource}`)
    },
    createProvider(payload) {
        return Api.post(`${resource}`, payload)
    },
    updateProvider(providerId, payload) {
        return Api.patch(`${resource}/${providerId}`, payload)
    },
    deleteProvider(providerId) {
        return Api.delete(`${resource}/${providerId}`)

    }
}