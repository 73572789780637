import moment from 'moment'

export default {
    formatMultipleDates(data) {
        const items = []
        for (let i = 0; i < data.length; i++) {
            data[i].createdAt = moment(data[i].createdAt).locale('de').format('L LT')
            data[i].updatedAt = moment(data[i].updatedAt).locale('de').format('L LT')
            items.push(data[i])
       }
        return items
    }
}