export default {
    lineItemsLinksToString(data) {
        let items = []
        for (let i = 0; i < data.length; i++) {
            let links = []
            if(data[i].lineItems) {
                for (let x = 0; x < data[i].lineItems.length; x++) {
                    let link = ''
                    if(data[i].lineItems[x].link) {
                        link = data[i].lineItems[x].link
                    } 
                    links.push(link)
                }
            }            
            if(links.length) {
                data[i].links = links.toString()
            }
            items.push(data[i])
        }
        return items
    }   
}