import Api from './index'

const resource = '/services'

export default {
    get() {
        return Api.get(`${resource}`)
    },
    createService(payload) {
        return Api.post(`${resource}`, payload)
    },
    updateService(serviceId, payload) {
        return Api.patch(`${resource}/${serviceId}`, payload)
    },
    deleteService(serviceId) {
        return Api.delete(`${resource}/${serviceId}`)
    },
    findServiceByVariantId(variationId) {
        return Api.get(`${resource}/variation/${variationId}`)
    }
}