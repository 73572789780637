import Vue from 'vue';
import VueRouter from 'vue-router';
import Dashboard from '../views/Dashboard.vue';
import Orders from '../views/Orders/Orders.vue'
import Providers from '../views/Providers/Providers.vue'
import Services from '../views/Services/Services.vue'


import NotFound from '../views/404.vue';

import { auth } from '../firebase'

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    meta: { 
      requiresAuth: true,
      layout: 'sidebar-left'
    }
  },
  {
    path: '/orders',
    name: 'Orders',
    component: Orders,
    meta: {
      requiresAuth: true,
      layout: 'sidebar-left'
    }
  },
  {
    path: '/services',
    name: 'Services',
    component: Services,
    meta: {
      requiresAuth: true,
      layout: 'sidebar-left'
    }
  },
  {
    path: '/providers',
    name: 'Providers',
    component: Providers,
    meta: {
      requiresAuth: true,
      layout: 'sidebar-left'
    }
  },
  {
    path: '/login',
    name: 'Login',
    meta: { 
      layout: 'centered'
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue'),
  },
  {
    path: '*',
    component: NotFound,
    meta: {
      layout: 'centered'
    }
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)

  if (requiresAuth && !auth.currentUser) {
    next('/login')
  } else {
    next()
  }
})

export default router;
